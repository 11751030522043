.btn-rounded{
  border-radius: 50px !important;
}
.btn-large{
  width: 260px;
  height: 60px;
  font-size: 18px !important;
  text-transform: none !important;
}

.btn-mid{
  width: 200px;
  height: 50px;
}
