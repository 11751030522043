@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

$primary-color: #db683d;
$secondary-color: #273879;
$black: #040d27;
$white: #ffffff;
$body: #f5f7fd;
$gray: #ccc;
$side-bar-width: 230px;
$light-gray: #f8f8f8;
$dim-white: #dbdbdf;
$light-white: #ffffff70;
$border-color: #dee2e6;

$primary-font-family: "Raleway", sans-serif;

$font-size-list: 10, 11, 12, 14, 16, 18, 20, 22, 24, 30;
$font-weight-list: 400, 600, 700, 800;
