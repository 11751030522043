/** @format */

@each $size in $font-size-list {
  .f-#{$size} {
    font-size: #{$size}px;
  }
}

@each $weight in $font-weight-list {
  .fw-#{$weight} {
    font-weight: $weight !important;
  }
}

.primary-heading {
  font-size: 60px;
  margin-bottom: 30px;
}
