.notfound {
  background-image: url("/../public/images/logoBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  //   height: 100vh;
  p.notfoundcontent {
    font-size: 42px;
    color: #9ba4af;
    margin-top: -40px;
    text-align: center;
  }
  img.notfoundimg {
    width: 600px;
    height: 600px;
    object-fit: contain;
  }
  button.notfoundbtn {
    font-size: 20px;
    text-transform: capitalize;
    border-radius: 38px;
    padding: 7px 50px;
    margin-bottom: 20px;
  }
  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    text-align: center;
    width: 100%;
    height: 50px;
    p {
      margin: 0;
    }
  }
}

@media (min-width: 1500px) {
  .notfound {
    height: 100vh;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .notfound {
    height: 100vh;
    img.notfoundimg {
      width: 400px;
      height: 400px;
    }
    p.notfoundcontent {
      font-size: 25px;
      text-align: center;
    }
  }
}
