/** @format */
.error-popup-heading {
  text-align: left;
  font: normal normal bold 36px Raleway;
  line-height: 1;
  margin: 0;
  text-transform: capitalize;
  margin-left: 10px;
}
.error-popup-details {
  text-align: left;
  font: normal normal normal 20px/44px Raleway;
  letter-spacing: 0px;
  color: #666666;
  a {
    color: #666666;
  }
}
