/** @format */

.vh-100 {
  height: 100vh;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
