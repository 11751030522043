/** @format */

.dashboard-header .responsive-menu-hamburger {
  display: none !important;
}
.dashboard-header{
  .row-heading-text-close-button {
    display: flex;
    .MuiTableCell-root:nth-child(1) {
      border-bottom: 0px !important;
    }
    .MuiTableCell-root:nth-child(2) {
      border-bottom: 0px !important;
    }
  }
}
.profile-button {
  text-align: left;
  position: absolute !important;
  right: 0;
  top: 0;
  text-transform: inherit !important;
  min-width: 320px;
  min-height: 85px;
  background: var(--unnamed-color-ee7623) 0% 0% no-repeat padding-box;
  background: #ee7623 0% 0% no-repeat padding-box;
  box-shadow: 15px 6px 20px #00000012;
  border-radius: 50px 0 0 50px !important;
  font-size: 14px !important;
  font-family: "Raleway" !important;
  font-weight: 400 !important;
  gap: 20px;
  line-height: 1.5 !important;
}
.profile-button-mob {
  display: none !important;
}

.profile-butto:hover {
  color: #f1f1f1;
  background-color: #ee7623 !important;
}

@media (max-width: 1440px) {
  .dashboard-header {
    .row-heading-text-close-button {
      display: flex;
      .MuiTableCell-root:nth-child(1) {
        padding-right: 0px;
      }
      .MuiTableCell-root:nth-child(2) {
        padding-left: 10px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 1250px) {
  .dashboard-header {
    margin-top: 0px;
    padding: 0px 40px;
    margin-bottom: 0px !important;
    button {
      height: max-content;
    }
  }
}

@media (min-width: 320px) and (max-width: 1024px) {
  .dashboard-header .responsive-menu-hamburger {
    display: table-cell !important;
  }
}

@media (min-width: 320px) and (max-width: 387px) {
  .row-heading-text-close-button {
    .responsive-menu-hamburger{
      .sidebar-close-icon{
        font-size: 25px;
      }
    }
    h1{
      font-size: 16px;
    }
  }
}

@media (min-width: 320px) and (max-width: 630px) {
  .dashboard-header {
    margin-top: 0px !important;
    .profile-button {
      display: none;
    }
    .profile-button-mob {
      display: block !important;
      border-radius: 50%;
      height: 60px;
      width: 60px;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .dashboard-header {
    margin-top: -10px;
  }
}
