/**
 * /*=============General Styling===============
 *
 * @format
 */

body {
  font-family: $primary-font-family;
  color: $black;
  background-color: $body;
  margin: 0;
  padding: 0;
  &.dark {
    background-color: $black;
    color: $body;
  }
}
a,
button {
  color: "inherit";
  text-decoration: none;
  &:focus,
  &:active {
    color: "#fff";
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  &.underline {
    &,
    &:hover {
      text-decoration: underline !important;
    }
  }
}

a {
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $primary-color;
    text-decoration: none !important;
  }
}

*,
.fancy-scroll {
  &::-webkit-scrollbar-track {
    background-color: $gray;
    border-radius: 10px;
    visibility: hidden;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
    visibility: hidden;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: transparent;
    transition: background-color 0.3s;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: #9d9d9d;
    }
  }
  &.scroll-transparent {
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
  }
}

.fancy-scroll {
  overflow-y: auto;
}

.danger {
  color: red;
}

.h-90 {
  min-height: 90vh;
}

.dashboard-header .MuiTableCell-root {
  max-width: 100% !important;
}
.MuiTableCell-root {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
